<template lang="pug">
    div.main-container(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/insigne-bg-stroke-grey.png') + ')'} ")
        div.overlay
        
        <!--  BEGIN CONTENT PART  -->
        div.main-content(id="content")
            div.layout-px-spacing
                div.page-header
                    div.page-title
                        h3 Edición de Preguntas
                    div
                       nav.breadcrumb-one(aria-label="breadcrumb")
                          ol.breadcrumb
                            //-   li.breadcrumb-item
                            //-     router-link(to="/pages/home") Mi Dashboard
                            //-   li.breadcrumb-item
                            //-     router-link(to="/pages/dashboardCenter") Centros
                            //-   li.breadcrumb-item.active(aria-current="page") Administración de centros
                              
                div.row.mt-5.pb-5
                    div.col-lg-6.col-md-6.layout-spacing
                        div.statbox.widget.box.box-shadow
                            div.widget-header
                                div.row
                                    div.col-xl-12.col-md-12.col-sm-12.col-12.text-center
                                        h4 Listado de Preguntas
                                div.widget-content.widget-content-area
                                    div.mx-auto.text-center

                                        div.text-left
                                            button.btn-sm.btn-primary(type="button" @click="Save") Agregar Pregunta
                                        div.table-responsive.mt-5
                                            table.table.table-bordered.table-hover.mb-4
                                                thead
                                                    tr
                                                        th No Pregunta
                                                        th(colspan=2) Acciones                                                
                                                tbody
                                                    tr(v-for="question in questionList")
                                                        td {{ question.sQuestion }}
                                                        td 
                                                            button.btn-sm.btn-primary.btn-block(type="button" @click="editQuestion(question.idQuestion)") Editar
                                                        td
                                                            button.btn-sm.btn-primary.btn-block(type="button" @click="delQuestion(question.idQuestion)") Eliminar
                    div.col-lg-6.col-md-6.layout-spacing
                        div.statbox.widget.box.box-shadow
                            div.widget-header
                                div.row
                                    div.col-xl-12.col-md-12.col-sm-12.col-12.text-center( v-if="model.sQuestion")
                                        h4 Edición de la {{ model.sQuestion }}

                                        div.widget-content.widget-content-area
                                            div.mx-auto.text-left



                                                form(role="form")
                                                    div.card-body
                                                        div.form-group.mb-6
                                                            label(for="sQuestion") Pregunta
                                                            input.form-control(type="text" id="sQuestion" v-model="model.sQuestion" readonly="true") 
                                                        div.form-group.mb-6
                                                            label(for="sQuestionDesc") Descripción de la pregunta
                                                            input.form-control(type="text" id="sQuestionDesc" v-model="model.sQuestionDesc" placeholder="Escriba un texto que describa la pregunta")
                                                        div.form-group.mb-6
                                                            label(for="sQuestionPath") Ruta de la pregunta
                                                            select.form-control.custom-select(id="sQuestionPath" v-model="model.idExamPaths")
                                                                option(value="0" selected) Selecciona una opción
                                                                option(:value=" examPath.idExamPaths" v-for="examPath in examPaths") {{ examPath.sFolderName }}
                                                        div.form-group.mb-6
                                                            input(type="checkbox"  :value = "model.bIsRequired" id="bIsRequired" v-model="model.bIsRequired")
                                                            label(for="bIsRequired" style="padding-left: 5px") Es una pregunta Requerida
                                                        div.form-group.mb-6
                                                            input(type="checkbox" :value = "model.bActive" id="bActive" v-model="model.bActive")
                                                            label(for="bActive" style="padding-left: 5px") Es una pregunta Activa
                                                        div.form-group.mb-3
                                                            button.btn.btn-primary(type="button" @click="updateQuestion") Guardar
                router-link.btn.btn-primary(to="/content/exams" ) Regresar


</template>
<script>
/* eslint-disable */
export default {
    name: 'AddQuestion',
    props: ['idExamPool'],
    components: {
    },    
    data() {
        return {
            questionList: {
                idQuestion: 0,
                sQuestion: '',
                sQuestionDesc: '',
                sQuestionPath: '',
                bIsRequired: false,
                idExamPool: 0,
                idExamPaths: 0
            },
            examPaths: 
            {
                idExamPaths: 0,
                sProjectPath: '',
                sFolderName: ''
            },
            model: {
                sQuestion: '',
                sQuestionDesc: '',
                idExamPaths: 0,
                bIsRequired: false,
                idExamPool: 0,
                bActive: false
            }
        }
    },
    methods: {
        getQuestions(){
            //Comentario para probar el git
            axios.get(apiURL + 'Exam/getQuestionList?idExamPool='+ this.idExamPool, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                this.questionList = response.data.questionList
                //this.examPaths = response.data.examPaths
            } )
            .catch( (error)  => {
                console.log("error")
            } )

        },


        async Save() {
            //Este dato se pone fijo para pruebas pero debe venir de un parametro
            this.model.idExamPool = this.idExamPool
            this.openLoading('Guardando Datos ...');

            await axios.post(apiURL + 'Exam/AddQuestion', this.model, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                Swal.close();
                if(response.data.bIsCorrect == false){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    return
                }
                Swal.fire('Datos Guardados correctamente')
                this.getQuestions()

            } )
            .catch( (error) => {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
            } )
        },        
        async updateQuestion() {
            //Este dato se pone fijo para pruebas pero debe venir de un parametro
            this.model.idExamPool = this.idExamPool
            this.openLoading('Guardando Datos ...');

            await axios.post(apiURL + 'Exam/updateQuestion', this.model, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                Swal.close();
                if(response.data.bIsCorrect == false){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    return
                }
                Swal.fire('Datos Guardados correctamente')
                this.getQuestions()

            } )
            .catch( (error) => {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
            } )
        },        

        async delQuestion(idQuestion) {
            console.log('idQuestion: ' + idQuestion)
            this.openLoading('Eliminando ...');

            await axios.post(apiURL + 'Exam/delQuestion?idQuestion=' + idQuestion, null, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                Swal.close();
                if(response.data.bIsCorrect == false){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    return
                }
                Swal.fire('Pregunta eliminada!!')
                this.getQuestions()

            } )
            .catch( (error) => {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
            } )
        },        

        async editQuestion(idQuestion) {
            this.model.sQuestion = this.questionList.find(o => o.idQuestion === idQuestion).sQuestion
            this.model.sQuestionDesc = this.questionList.find(o => o.idQuestion === idQuestion).sQuestionDesc
            this.model.sQuestionPath = this.questionList.find(o => o.idQuestion === idQuestion).sQuestionPath
            this.model.bIsRequired = this.questionList.find(o => o.idQuestion === idQuestion).bIsRequired
            this.model.idExamPool = this.questionList.find(o => o.idQuestion === idQuestion).idExamPool
            this.model.idQuestion = idQuestion
            this.model.idExamPaths = this.questionList.find(o => o.idQuestion === idQuestion).idExamPaths
            this.model.bActive = this.questionList.find(o => o.idQuestion === idQuestion).bActive
            

            await axios.get(apiURL + 'Exam/getPathsList?idExamPool='+ this.idExamPool, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                this.examPaths = response.data.pathsList
            } )
            .catch( (error)  => {
                console.log("error")
            } )

            if (this.model.idExamPaths != 0) {
                let stringValue = this.examPaths.find(o => o.idExamPaths === this.model.idExamPaths).sFolderName
                stringValue = stringValue.replace('En Uso', 'Actual')

                this.examPaths.find(o => o.idExamPaths === this.model.idExamPaths).sFolderName = stringValue
                console.log(stringValue)
            }




        },                





    },
    mounted() {
        this.getQuestions()
    }    
        
}
</script>